<template>
  <div class="ProfilePictureView">
    <div class="headerContainer">
      <h1>{{ $t('header') }} </h1>
      <div class="subHeader">{{ $t('subHeader') }}</div>
    </div>

    <div class="imageContainer">

      <PictureInput
      :style="{'background-image':'@/assets/images/upload-profile-image.png'}"
      width="300"
      height="300"
      :crop="true"
      :hide-change-button="true"
      accept="image/jpeg"
      @change="pictureSelected"
      ref="picture" />

      <img v-if="!saved" class="imagePlaceholder" src="@/assets/images/upload-profile-image.png">

    </div>

    <div
      class="saved"
      v-if="saved">

      {{ $t('saved') }}
      
      <div
        class="button"
        @click="next()">
        {{ $t('next') }}
      </div>
    </div>
  </div>
</template>

<translations>
    header: Upload your profile image
    header_no: Last opp profilbildet ditt

    subHeader: Drag and drop or click to add your image (JPEG format)
    subHeader_no: Dra og slipp eller klikk for å laste opp bildet (JPEG-format)

    drag: Drag or click here
    drag_no: Dra eller klikk her

    saved: Your profile picture was updated
    saved_no: Ditt profilbilde har blitt oppdatert

    next: Finish
    next_no: Ferdig 

</translations>

<script>

import PictureInput from 'vue-picture-input';
import settings from '@/settings';
import axios from 'axios';

export default {
  components: {
    PictureInput
  },

  data: function() {
    return {
      upload: {fields: {}},
      saved: false,
    }
  },

  props: {},

  mounted() {},

  methods: {
    async pictureSelected() {
      const url = `${settings.endpoints.bouncer}/upload/avatar/`;
      const result = await axios.get(url);
      this.upload = result.data;
      this.save();
    },

    async save() {
      this.saved = false;
      const body = this.$refs.picture.$refs.fileInput.files[0];
      await fetch(this.upload.url, {
        method: "PUT",
        body,
      });
      this.saved = true;
      this.$store.dispatch('moduleAuth/updateAuthToken');
    },

    next() {
      this.$router.push({ name: 'DashboardProfileView' });
    }
  },

  computed: {},

  filters: {},
}
</script>

<style lang="scss" scoped>
.ProfilePictureView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .headerContainer {
    margin-bottom: 3em;
    text-align: center;
    opacity: 0.9;

    @media (max-height: 620px) and (max-width: 620px) {
      margin-bottom: 2em;
    }

    h1 {
      margin-bottom: 0.5em;
      font-weight: 600;
      @media (max-width: 620px) {
        font-size: 1.9em;
      }
    }
    .subHeader {
      opacity: 0.6;
      font-size: 1.3em;
      padding: 0 5vw;
      line-height: 1.5em;
      @media (max-width: 620px) {
        font-size: 1.2em;
      }
    }
  }

  .imageContainer {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-height: 620px) and (max-width: 620px) {
      height: 50vh;
     }
  }

  .imagePlaceholder{
    position: absolute;
    height: 300px;
    width: 300px;
    opacity: 0.25;
    @media (max-height: 620px) and (max-width: 620px) {
      height: 35vh;
      width: 35vh;
     }
  }

  /deep/ .picture-preview {
    background-color: rgba(0, 255, 255, 0);
    @media (max-height: 620px) {
      height: 35vh!important;
      width: 35vh!important;
     }
  }
  /deep/ .picture-inner-text {
    opacity: 0;
  }
  /deep/ .picture-inner {
    opacity: 0;
  }
  /deep/ .preview-container {
     @media (max-height: 620px) and (max-width: 620px) {
      height: 35vh!important;
      width: 35vh!important;
     }
  }

  .saved {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    text-align: center;
    color: white;
    width: 15em;
    @media (max-height: 620px) and (max-width: 620px) {
      margin-top: 2em;
    }
  }

  .next {
    margin: 1em;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(255, 255, 255);
    background:rgb(100, 144, 225);
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
    border-radius: 0.3em;
    width: 10em;
    height: 2.4em;
    margin: 1em 0 2.2em 0;
  }
  .button:hover{
    cursor: pointer;
  }
}
</style>

